<template>
  <div class="mt-2">
    <el-row>
      <el-col :span="12"> {{ title }} </el-col>
      <el-col :span="12">
        <el-select
          v-model="standardQuestion"
          size="mini"
          @change="addStandardOptions"
        >
          <el-option
            v-for="que in standardQuestions"
            :value="que._id"
            :key="que._id"
            :label="que.name"
          >
            {{ que.name }}
          </el-option>
        </el-select>
      </el-col>
    </el-row>

    <el-row>
      <el-col :span="12">
        <ul v-if="field.options.length">
          <li v-for="(option, index) in field.options" :key="index">
            <span>
             {{ String.fromCharCode((index % 52) + (index > 25 ? 71 : 65)) }}.
              <div style="display: flex">
                <el-input v-model="field.options[index]" size="mini"></el-input>
                <el-link :underline="false" @click="removeOption(index)">
                  <i class="el-icon-circle-close" style="margin-left: 15px"></i>
                </el-link>
              </div>
            </span>
          </li>
        </ul>
        <el-button size="mini" round @click="addOption" class="mt-1"
          >Add</el-button
        >
        <el-checkbox v-model="field.has_other_option" class="float-right mt-1">
          Add Other field
        </el-checkbox>
      </el-col>
    </el-row>
    <el-row class="mt-3">
      <el-col :span="12" v-if="field.has_other_option">
        <span> Enter field label </span>
        <el-input
          placeholder="Enter field label"
          size="mini"
          v-model="field.other_option_label"
        ></el-input>
      </el-col>
    </el-row>
    <hr />
    <el-row>
      <el-col :span="12">
        Options Style

        <el-select v-model="field.question_options_format">
          <el-option
            v-for="(format, index) of options_format_types"
            :value="format.value"
            :key="index"
            :label="format.label"
          >
            <span style="float: left">{{ format.label }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px"
              ><i :class="format.icon"></i
            ></span>
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="12">
        Max Allowed Options to selected
        <el-input-number v-model="field.max_selection" :controls="false" />
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  props: ["field", "title", "standardQuestions"],
  data() {
    return {
      options_format_types: [
        {
          label: "Vertical List",
          value: "VERTICAL_LIST",
          icon: "el-icon-bottom",
        },
        {
          label: "Horizantal List",
          value: "HORIZANTAL_LIST",
          icon: "el-icon-right",
        },
        {
          label: "Dropdown Select",
          value: "DROP_DOWN",
          icon: "el-icon-finished",
        },
      ],
      default_options_format_type: "VERTICAL_LIST",
      standardQuestion: "",
    };
  },
  mounted() {
    if (!this.field.options || !this.field.options.length) {
      this.$set(this.field, "options", [""]);
      this.$set(this.field, "max_selection", 1);
    }

    if (!this.field.question_options_format) {
      this.$set(
        this.field,
        "question_options_format",
        this.default_options_format_type
      );
    }

    if (!this.field.other_option) {
      this.$set(this.field, "other_option", "");
    }
    if (!this.field.other_option_label) {
      this.$set(this.field, "other_option_label", "");
    }
    if ("has_other_option" in this.field) {
      this.$set(
        this.field,
        "has_other_option",
        this.field.has_other_option ? true : false
      );
    }
  },
  methods: {
    addStandardOptions(id) {
      const selectedQuestion = this.standardQuestions.find((e) => e._id == id);
      if (selectedQuestion && selectedQuestion.options) {
        this.field.options = [];
        this.field.options = [...selectedQuestion.options];
      }
    },
    removeOption(index) {
      this.field.options.splice(index, 1);
    },
    addOption() {
      this.field.options.push("");
    },
    addMaxSelection() {
      this.field.max_selection = 1;
    },
  },
};
</script>

<style lang="scss"></style>
